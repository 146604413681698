<template>
	<div>
    <Breadcrumbs :items="crumbs" />
  	<div class="white">
      <v-container>
        <div class="display-3">
          <span class="thin">Patented</span>
        </div><br />
        <div class="display-4">
          <span class="thick">Technology</span>
        </div>
        <div>
          <v-btn small outline class="ml-0 mt-2" :to="{ name: 'patents' }">See Our Patents <v-icon right>keyboard_arrow_right</v-icon></v-btn>
          <v-btn small outline class="ml-2 mt-2" :to="{ name: 'data' }">Data Analytics <v-icon right>keyboard_arrow_right</v-icon></v-btn>
        </div>
      </v-container>
  	</div>

    <div class="grey darken-4 white--text">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Interact with your customers in real-time, while collecting powerful, actionable data.</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Simple setup with mobile SDK (Software Development Kit)</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Real-time customer communication via email, SMS & push notifications</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Secure business intelligence in real time</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Creative messaging</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Real time data gathering</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Data management platform</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Real time heat maps</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Built for scalability through the use of Amazon web-based services</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      interval: {},
      value: 0,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Our Technology',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
  mounted () {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0)
      }
      this.value += 10
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>